import React, { useContext, useEffect, useState } from "react";
import { Modal, Alert, Button } from "react-bootstrap";
import { ThemeContext } from "../../../context/ThemeContext";
import loadingGif from "../../../images/loading.gif";

import axios from 'axios';
import { baseURL } from "../../../baseURL";
import Cookies from 'js-cookie';

const SubscribeAlert = () => {
  const [basicModal, setBasicModal] = useState(false);
  const [subscribedStatus, setSubscribedStatus] = useState(true);
  const [loading, setLoading] = useState(false);

  async function subscribe() {
    setLoading(true);
    const data = {
      token: Cookies.get('token')
    }

    try {
      const response = await axios.post(baseURL+'/api/user/subscribe', data);
      if(response.data.status === 'ok') {
        Cookies.set('subscribe', "1", { expires: 1, path: '/'});
        setBasicModal(false);
        setSubscribedStatus(true);
      }
    } catch(e) {
      
    }
    setLoading(false);
  }

  useEffect(() => {
    const subscribe = Cookies.get('subscribe');
    if(String(subscribe) == "0") {
      setSubscribedStatus(false);
    }
  }, []);

  return (
    <div className="col-12">
      {subscribedStatus == false && (
        <>
          <Alert variant="info" className="alert-dismissible fade show">
            You have unsubscribed from our newsletter. Please subscribe again to avoid missing important notifications.<br/>
            <button className="btn btn-xs btn-info" onClick={() => setBasicModal(true)}>Subscribe</button>
          </Alert>

          <Modal className="fade" show={basicModal}>
            <Modal.Header>
              <Modal.Title>Subscribe</Modal.Title>
              <Button
                variant=""
                className="close"
                onClick={() => setBasicModal(false)}
              >
                <span>&times;</span>
              </Button>
            </Modal.Header>
            <Modal.Body>
              
              <div className="basic-form">
                  <p className="text-center mb-0 ">Subscribe to our newsletter to receive important notifications.</p>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label"></label>
                    <div className="col-sm-12">
                      <button className="btn btn-md btn-block btn-info" onClick={() => subscribe()}>
                        {loading === false && (
                          <span>Subscribe</span>
                        )}
                        {loading === true && (
                          <span><img alt="" src={loadingGif} width="18" /></span>
                        )}
                      </button>
                    </div>
                  </div>

              </div>

            </Modal.Body>
          </Modal>
        </>
      )}
    </div>
  );
};

export default SubscribeAlert;

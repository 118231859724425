import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import { Col, Card, Alert } from "react-bootstrap";
import {SetBackgroundTheme} from "../../setBackgroundTheme";
import axios from 'axios';
import { baseURL } from "../../../baseURL";
import Cookies from 'js-cookie';

const Feedback = () => {
  const [topic, setTopic] = useState('General Questions');
  const [message, setMessage] = useState('');
  const [topicDescription, setTopicDescription] = useState('For any inquiries about our services or website.');
  const [sendingStatus, setSendingStatus] = useState(false);

  const [disabledButton, setDisabledButton] = useState(true);
  const [error, setError] = useState('');

  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    SetBackgroundTheme(changeBackground, Cookies);
  }, [changeBackground]);

  useEffect(() => {
    if(topic === 'General Questions') {
      setTopicDescription('For any inquiries about our services or website.')
    } else if(topic === 'Technical Support') {
      setTopicDescription("Report bugs, technical issues, or challenges you're facing.")
    } else if(topic === 'Account or Billing Questions') {
      setTopicDescription('Assistance with account settings, subscriptions, or payment-related concerns.');
    } else if(topic === 'Feature Requests') {
      setTopicDescription("Suggest new features or improvements you'd like to see.")
    } else if(topic === 'Other') {
      setTopicDescription('If your topic doesn’t fit into the above categories, let us know here.')
    }
  }, [topic]);

  useEffect(() => {
    if(message === '') {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  }, [message]);

  async function sendFeedback() {
    setDisabledButton(true);

    const data = {
      token: Cookies.get('token'),
      email: Cookies.get('email'),
      topic: topic,
      message: message
    }

    try {
      const response = await axios.post(baseURL+'/api/send_feedback', data);
      if(response.data.status === 'ok') {
        setSendingStatus(true);
      } else {
        setError(response.data.text);
      }
    } catch(err) {

    }
    setDisabledButton(false);
  }

  return (
    <>
      <div className="row">

        <Col xl="12">
          <Card>
            <Card.Header className="d-block card-header">
              <h4 className="card-title">We Value Your Feedback</h4>
              <Card.Text className="m-0 subtitle">
                Share your thoughts, ask questions, or provide suggestions—we're here to listen!
              </Card.Text>
            </Card.Header>
            <Card.Body className="card-body">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Your Email</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        value={Cookies.get('email')}
                        disabled={true}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Topic</label>
                    <div className="col-sm-9">
                      <select className="form-control" onChange={(e) => setTopic(e.target.value)}>
                        <option value={'General Questions'}>General Questions</option>
                        <option value={'Technical Support'}>Technical Support</option>
                        <option value={'Account or Billing Questions'}>Account or Billing Questions</option>
                        <option value={'Feature Requests'}>Feature Requests</option>
                        <option value={'Other'}>Other</option>
                      </select>

                      <small>{topicDescription}</small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Message</label>
                    <div className="col-sm-9">
                      <textarea
                        type="text"
                        className="form-control"
                        placeholder="Message"
                        rows={6}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label"></label>
                    <div className="col-sm-9">
                      {sendingStatus === false && (
                        <button className="btn btn-md btn-info" disabled={disabledButton} onClick={() => sendFeedback()}>
                          <span>Send message</span>
                        </button>
                      )}

                      {sendingStatus === true && (
                        <Alert variant="success" className="mt-3 alert-dismissible fade show">
                          The message has been sent successfully.
                        </Alert>
                      )}

                      {error !== "" && (
                        <Alert variant="danger" className="mt-3 alert-dismissible fade show">
                          {error}
                        </Alert>
                      )}

                    </div>
                  </div>

                </form>
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* <Col xl="12">
          <Card>
            <Card.Header className="d-block card-header">
              <h4 className="card-title">Chat with the Developer</h4>
              <Card.Text className="m-0 subtitle">
                Gain insights and troubleshoot issues directly with the developers.
              </Card.Text>
            </Card.Header>
            <Card.Body className="card-body">
              <div className="row">
                <div className="col-12 pt-1 pb-1">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 50 50">
                    <path d="M 25 2 C 12.318 2 2 12.318 2 25 C 2 28.96 3.0228906 32.853062 4.9628906 36.289062 L 2.0371094 46.730469 C 1.9411094 47.073469 2.03325 47.440312 2.28125 47.695312 C 2.47225 47.892313 2.733 48 3 48 C 3.08 48 3.1612344 47.989703 3.2402344 47.970703 L 14.136719 45.271484 C 17.463719 47.057484 21.21 48 25 48 C 37.682 48 48 37.682 48 25 C 48 12.318 37.682 2 25 2 z M 16.642578 14 C 17.036578 14 17.428437 14.005484 17.773438 14.021484 C 18.136437 14.039484 18.624516 13.883484 19.103516 15.021484 C 19.595516 16.189484 20.775875 19.058563 20.921875 19.351562 C 21.069875 19.643563 21.168656 19.984047 20.972656 20.373047 C 20.776656 20.762047 20.678813 21.006656 20.382812 21.347656 C 20.086813 21.688656 19.762094 22.107141 19.496094 22.369141 C 19.200094 22.660141 18.892328 22.974594 19.236328 23.558594 C 19.580328 24.142594 20.765484 26.051656 22.521484 27.597656 C 24.776484 29.583656 26.679531 30.200188 27.269531 30.492188 C 27.859531 30.784188 28.204828 30.734703 28.548828 30.345703 C 28.892828 29.955703 30.024969 28.643547 30.417969 28.060547 C 30.810969 27.477547 31.204094 27.572578 31.746094 27.767578 C 32.288094 27.961578 35.19125 29.372062 35.78125 29.664062 C 36.37125 29.956063 36.766062 30.102703 36.914062 30.345703 C 37.062062 30.587703 37.062312 31.754234 36.570312 33.115234 C 36.078313 34.477234 33.717984 35.721672 32.583984 35.888672 C 31.565984 36.037672 30.277281 36.10025 28.863281 35.65625 C 28.006281 35.38625 26.907047 35.028734 25.498047 34.427734 C 19.575047 31.901734 15.706156 26.012047 15.410156 25.623047 C 15.115156 25.234047 13 22.46275 13 19.59375 C 13 16.72475 14.524406 15.314469 15.066406 14.730469 C 15.608406 14.146469 16.248578 14 16.642578 14 z"></path>
                  </svg>
                  <a href="#" className="text-black pl-2">WhatsApp: +123</a>
                </div>
                <div className="col-12 pt-1 pb-1">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 50 50">
                    <path d="M25,2c12.703,0,23,10.297,23,23S37.703,48,25,48S2,37.703,2,25S12.297,2,25,2z M32.934,34.375	c0.423-1.298,2.405-14.234,2.65-16.783c0.074-0.772-0.17-1.285-0.648-1.514c-0.578-0.278-1.434-0.139-2.427,0.219	c-1.362,0.491-18.774,7.884-19.78,8.312c-0.954,0.405-1.856,0.847-1.856,1.487c0,0.45,0.267,0.703,1.003,0.966	c0.766,0.273,2.695,0.858,3.834,1.172c1.097,0.303,2.346,0.04,3.046-0.395c0.742-0.461,9.305-6.191,9.92-6.693	c0.614-0.502,1.104,0.141,0.602,0.644c-0.502,0.502-6.38,6.207-7.155,6.997c-0.941,0.959-0.273,1.953,0.358,2.351	c0.721,0.454,5.906,3.932,6.687,4.49c0.781,0.558,1.573,0.811,2.298,0.811C32.191,36.439,32.573,35.484,32.934,34.375z"></path>
                  </svg>
                  <a href="#" className="text-black pl-2">Telegram: @BettrTradeTech</a>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col> */}

      </div>
    </>
  );
};

export default Feedback;

import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import {SetBackgroundTheme} from "../../setBackgroundTheme";
import axios from 'axios';
import { baseURL } from "../../../baseURL";
import Cookies from 'js-cookie';

const ExchangesConnect = () => {
  const [copiedIpToClipboard, setCopiedIpToClipboard] = useState(false);
  const [ip, setIp] = useState('');
  

  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    SetBackgroundTheme(changeBackground, Cookies);
  }, []);

  async function copyToClipboard() {
    try {
      await navigator.clipboard.writeText(ip);
      setCopiedIpToClipboard(true);
      await delay(3000);
      setCopiedIpToClipboard(false);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  async function getTrustedIp() {
    const data = { token: Cookies.get('token') }
    try {
      const response = await axios.post(baseURL+'/api/get_trusted_ip', data);
      if(response.data.status === 'ok') {
        setIp(response.data.ip);
      } else {
        
      }
    } catch(err) {

    }
  }

  useEffect(() => {
    getTrustedIp();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Get Started</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <p>To get started using BETTR Invest, you will need a verified Binance.com account.</p>
                  <p>Binance sign-up link: <a href="https://www.binance.com/en/register" className="btn btn-sm btn-info" target="_blank" rel="noreferrer">https://www.binance.com/</a></p>
                  <p>Note: Depositing on BETTR Invest is for paying invoices, not for trading!</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Video Instruction</h4>
            </div>
            <div className="card-body">
              <p className="text-left">Watch our step-by-step video guide to easily set up your Binance account and connect it to BettrTrade.</p>
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/tn6bOisU058?si=r9DvhZG2Y9afMNd2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">API Key</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                  <h5>Once you have a Binance account, create an API key:</h5>
                  <p>Step 1. Open the API Management section in your Binance account: <a href="https://www.binance.com/en/my/settings/api-management" target="_blank" rel="noreferrer">API Management</a></p>
                  <p>Step 2. Click on the "Create API" button to create the API Key.</p>
                  <div class="row col-12 mb-3">
                    <img src="/images/binance/create_api_key.png" width="100%" alt="" />
                  </div>
                  <p>Step 3. Select "System generated" type key.</p>
                  <div class="row col-12 mb-3">
                    <img src="/images/binance/api_key_type.png" width="100%" alt="" />
                  </div>
                  <p>Step 4. Enter the Label for the API Key. It can be any name.</p>
                  <p>Step 5. Complete the verifications to continue.</p>
                  <div class="row col-12 mb-3">
                    <img src="/images/binance/complete_verification.png" width="100%" alt="" />
                  </div>
                  <p>
                    Step 6. Enable Spot and Margin Trading:<br/>
                    1. Click on the "Edit restrictions" button.<br/>
                    2. Check the "Restrict access to trusted IPs only (Recommended)" checkbox.<br/>
                    3. Add IP address: <span onClick={() => copyToClipboard()} style={{cursor: 'pointer'}}><u>{ip} {copiedIpToClipboard === false && (<small className=""> (Click to copy)</small>)}</u></span>
                    
                    {copiedIpToClipboard === true && (
                      <small className="text-success"> (The IP address has been copied to the clipboard.)</small>
                    )}
                    <br/>
                    4. Check the "Enable Spot & Margin Trading" checkbox and then click "Save."<br/>
                  </p>
                  <div class="row col-12 mb-3">
                    <img src="/images/binance/api_restriction_1.png" width="100%" alt="" />
                  </div>
                  <div class="row col-12 mb-3">
                    <img src="/images/binance/api_restriction_2.png" width="100%" alt="" />
                  </div>
                  <div class="row col-12 mb-3">
                    <img src="/images/binance/api_restriction_3.png" width="100%" alt="" />
                  </div>
                  <p>Make sure withdrawl is not enabled and all other options match the image.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Connect Exchange</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                  <p>Once your API key is created, connect it on BETTR Invest under "Connect exchange", or go to:  <Link to="/exchanges/connect" class="btn btn-sm btn-info">Connect</Link></p>
                  <p>Once you've connected it, you're done!</p>
                  <p>Trading will begin shortly, and all statistics will display on your dashboard.</p>
                  <p>Remember, you must have at least <b>100</b> USDT in your Binance account!</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default ExchangesConnect;

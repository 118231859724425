import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import { Alert } from "react-bootstrap";
import {SetBackgroundTheme} from "../../setBackgroundTheme";
import axios from 'axios';
import { baseURL } from "../../../baseURL";
import Cookies from 'js-cookie';

const ExchangesConnect = ({ history }) => {
  const [exchangePlatform, setExchangePlatform] = useState('binance');
  const [internalName, setInternalName] = useState('');
  const [apiKey, setAPIKey] = useState('');
  const [apiSecret, setAPISecret] = useState('');
  const [tradingCapital, setTradingCapital] = useState(100);
  const [positionSize, setPositionSize] = useState(3);
  const [positionSizeType, setPositionSizeType] = useState('percent');
  const [reinvestProfit, setReinvestProfit] = useState(1);

  const [disabledButton, setDisabledButton] = useState(false);
  const [error, setError] = useState('');

  const [q1, setQ1] = useState(null);
  const [q2, setQ2] = useState(null);
  const [q3, setQ3] = useState(null);
  const [q4, setQ4] = useState(null);
  const [q5, setQ5] = useState(null);
  const [q6, setQ6] = useState(null);
  const [q7, setQ7] = useState(null);
  const [q8, setQ8] = useState(null);
  const [q9, setQ9] = useState(null);
  const [responsibilityConfirmed, setResponsibilityConfirmed] = useState(null);
  
  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    getLicenseInfo();
    SetBackgroundTheme(changeBackground, Cookies);
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => changePositionSize(positionSize), 1000);
    return () => clearTimeout(timeOutId);
  }, [positionSize]);

  async function getLicenseInfo() {
    const data = {
      token: Cookies.get('token')
    }

    try {
      const response = await axios.post(baseURL+'/api/lifetime_license/get_user_info', data);
      console.log(response.data);
      if(response.data.status === 'ok') {
        if(response.data.lifetime_license === 0 && response.data.free_trial_active === 0) {
          history.push("/exchanges");
        }
      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }

    // setLoadingOpenPositions(false);
  }

  function changePositionSize(values, position_size_type) {
    // const values = parseFloat(e.target.value);
    if(values < 0) {
      setPositionSize(1);
      return "";
    }

    if(positionSizeType === 'usdt' || position_size_type === 'usdt') {
      if(values < 8) {
        setPositionSize(8);
        return "";
      }

      if(values > tradingCapital) {
        setPositionSize(tradingCapital);
        return "";
      }
    }

    if(positionSizeType === 'percent' || position_size_type === 'percent') {
      if(values > 100) {
        setPositionSize(100);
        return "";
      }

      if(values <= 0) {
        setPositionSize(1);
        return "";
      }
    }

    setPositionSize(values);
  }

  function changePositionSizeType(value) {
    setPositionSizeType(value);
    changePositionSize(positionSize, value);
  }

  async function connectExchange() {

    if(positionSizeType === 'usdt' && positionSize < 8) {
      setError("The minimum position size is 8 USDT.");
      return "";
    }

    setDisabledButton(true);
    const data = {
      token: Cookies.get('token'),
      name: internalName,
      platform: exchangePlatform,
      key: apiKey,
      secret: apiSecret,
      invested_max: tradingCapital,
      positions_number_max: 10,
      positions_number_same_pair_max: 1,
      position_size: positionSize,
      position_size_type: positionSizeType,
      reinvest_profits: reinvestProfit
    }

    try {
      const response = await axios.post(baseURL+'/api/exchange/connect', data);
      if(response.data.status === 'ok') {
        // setExchanges(response.data.exchanges);
        history.push("/exchanges");
      } else {
        setError(response.data.text);
      }
    } catch(err) {

    }
    setDisabledButton(false);
  }




  // // Active pagginarion
  // activePag.current === 0 && chageData(0, sort);
  // // paggination
  // let paggination = Array(Math.ceil(data.length / sort))
  //   .fill()
  //   .map((_, i) => i + 1);

  // // Active paggination & chage data
  // const onClick = (i) => {
  //   activePag.current = i;
  //   chageData(activePag.current * sort, (activePag.current + 1) * sort);
  //   settest(i);
  // };

  function saveResponsibility() {
    if(q1 === true && q2 === true && q3 === true && q4 === true && q5 === true && q6 === true && q7 === true && q8 === true && q9 === true) {
      setResponsibilityConfirmed(true);
    } else {
      setResponsibilityConfirmed(false);
    }
  }

  useEffect(() => {
    setResponsibilityConfirmed(null);
  }, [q1, q2, q3, q4, q5, q6, q7, q8, q9]);

  return (
    <>
      <div className="row">

        {responsibilityConfirmed !== true && (
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Responsibility</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <form onSubmit={(e) => e.preventDefault()}>

                    <div className="form-group row">
                      <label className="col-12 col-form-label text-black">1. Do you acknowledge that trading involves financial risk and that you may lose some or all of your investment?</label>
                      <div className="col-12">
                        <div className="row col-12">
                          <div className="radio">
                            <label>
                              <input type="radio" name="q1" value="Yes" checked={q1} onClick={() => setQ1(true)} /> Yes
                            </label>
                            <label className="ml-3">
                              <input type="radio" name="q1" value="No" checked={!q1} onClick={() => setQ1(false)} /> No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr/>

                    <div className="form-group row">
                      <label className="col-12 col-form-label text-black">2. Do you agree that BettrTrade provides tools and information only for educational and informational purposes?</label>
                      <div className="col-12">
                        <div className="row col-12">
                          <div className="radio">
                            <label>
                              <input type="radio" name="q2" value="Yes" checked={q2} onClick={() => setQ2(true)} /> Yes
                            </label>
                            <label className="ml-3">
                              <input type="radio" name="q2" value="No" checked={!q2} onClick={() => setQ2(false)} /> No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr/>

                    <div className="form-group row">
                      <label className="col-12 col-form-label text-black">3. Do you understand that BettrTrade does not guarantee any specific financial outcomes or profits from trading?</label>
                      <div className="col-12">
                        <div className="row col-12">
                          <div className="radio">
                            <label>
                              <input type="radio" name="q3" value="Yes" checked={q3} onClick={() => setQ3(true)} /> Yes
                            </label>
                            <label className="ml-3">
                              <input type="radio" name="q3" value="No" checked={!q3} onClick={() => setQ3(false)} /> No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr/>

                    <div className="form-group row">
                      <label className="col-12 col-form-label text-black">4. Do you accept that all trading decisions makes at your own risk?</label>
                      <div className="col-12">
                        <div className="row col-12">
                          <div className="radio">
                            <label>
                              <input type="radio" name="q4" value="Yes" checked={q4} onClick={() => setQ4(true)} /> Yes
                            </label>
                            <label className="ml-3">
                              <input type="radio" name="q4" value="No" checked={!q4} onClick={() => setQ4(false)} /> No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr/>

                    <div className="form-group row">
                      <label className="col-12 col-form-label text-black">5. Do you agree that BettrTrade does not provide personalized financial advice or recommendations?</label>
                      <div className="col-12">
                        <div className="row col-12">
                          <div className="radio">
                            <label>
                              <input type="radio" name="q5" value="Yes" checked={q5} onClick={() => setQ5(true)} /> Yes
                            </label>
                            <label className="ml-3">
                              <input type="radio" name="q5" value="No" checked={!q5} onClick={() => setQ5(false)} /> No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr/>

                    <div className="form-group row">
                      <label className="col-12 col-form-label text-black">6. Do you confirm that you are solely responsible for financial actions and their consequences?</label>
                      <div className="col-12">
                        <div className="row col-12">
                          <div className="radio">
                            <label>
                              <input type="radio" name="q6" value="Yes" checked={q6} onClick={() => setQ6(true)} /> Yes
                            </label>
                            <label className="ml-3">
                              <input type="radio" name="q6" value="No" checked={!q6} onClick={() => setQ6(false)} /> No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr/>

                    <div className="form-group row">
                      <label className="col-12 col-form-label text-black">7. Do you acknowledge that past performance is not indicative of future results in trading?</label>
                      <div className="col-12">
                        <div className="row col-12">
                          <div className="radio">
                            <label>
                              <input type="radio" name="q7" value="Yes" checked={q7} onClick={() => setQ7(true)} /> Yes
                            </label>
                            <label className="ml-3">
                              <input type="radio" name="q7" value="No" checked={!q7} onClick={() => setQ7(false)} /> No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr/>

                    <div className="form-group row">
                      <label className="col-12 col-form-label text-black">8. Do you agree that BettrTrade is not liable for any financial losses you may incur while trading?</label>
                      <div className="col-12">
                        <div className="row col-12">
                          <div className="radio">
                            <label>
                              <input type="radio" name="q8" value="Yes" checked={q8} onClick={() => setQ8(true)} /> Yes
                            </label>
                            <label className="ml-3">
                              <input type="radio" name="q8" value="No" checked={!q8} onClick={() => setQ8(false)} /> No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr/>

                    <div className="form-group row">
                      <label className="col-12 col-form-label text-black">9. Do you agree that it is your responsibility to ensure compliance with local laws and regulations when trading?</label>
                      <div className="col-12">
                        <div className="row col-12">
                          <div className="radio">
                            <label>
                              <input type="radio" name="q9" value="Yes" checked={q9} onClick={() => setQ9(true)} /> Yes
                            </label>
                            <label className="ml-3">
                              <input type="radio" name="q9" value="No" checked={!q9} onClick={() => setQ9(false)} /> No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr/>

                    {responsibilityConfirmed === false && (
                      <Alert variant="danger" className="mt-3 alert-dismissible fade show">
                        We understand your decision, but to use BettrTrade, you must acknowledge and agree to the terms and conditions outlined. These terms emphasize your full responsibility for trading decisions and outcomes.<br/><br/>
                        Unfortunately, if you do not agree, you will not be able to access or use our platform.<br/><br/>
                        Please review the questions again and ensure you fully understand the risks and responsibilities associated with trading before proceeding.<br/><br/>
                        If you have any questions or concerns, feel free to contact our support team.
                      </Alert>
                    )}

                    <div className="form-group row">
                      <div className="col-sm-9">
                        <button className="btn btn-md btn-info" onClick={() => saveResponsibility()}>
                          Confirm
                        </button>
                      </div>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        )}

        {responsibilityConfirmed === true && (
          <>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Connect new exchange</h4>
                </div>
                <div className="card-body">
                  <div className="basic-form">
                    <form onSubmit={(e) => e.preventDefault()}>

                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Exchange</label>
                        <div className="col-sm-9">
                          <select className="form-control" onChange={(e) => setExchangePlatform(e.target.value)}>
                            <option value={'binance'}>Binance</option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Internam name</label>
                        <div className="col-sm-9">
                          <input
                            type="email"
                            className="form-control"
                            placeholder=""
                            onChange={(e) => setInternalName(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">API Key</label>
                        <div className="col-sm-9">
                          <input
                            type="email"
                            className="form-control"
                            placeholder=""
                            onChange={(e) => setAPIKey(e.target.value)}
                          />
                        </div>
                      </div>
                      
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">API Secret</label>
                        <div className="col-sm-9">
                          <input
                            type="email"
                            className="form-control"
                            placeholder=""
                            onChange={(e) => setAPISecret(e.target.value)}
                          />
                        </div>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="basic-form">
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Trading Capital, USDT</label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            placeholder=""
                            value={tradingCapital}
                            onChange={(e) => setTradingCapital(e.target.value)}
                          />
                        </div>
                      </div>

                      {/* <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Position Size</label>
                        <div className="col-sm-9">
                          <div className="row">
                            <div className="col-6">
                              <input
                                type="number"
                                className="form-control"
                                placeholder=""
                                min={0}
                                value={positionSize}
                                onChange={(e) => setPositionSize(e.target.value)}
                              />
                            </div>
                            <div className="col-6">
                              <select className="form-control" onChange={(e) => changePositionSizeType(e.target.value)}>
                                <option value={'usdt'}>USDT</option>
                                <option value={'percent'}>%</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Reinvest Profits</label>
                        <div className="col-sm-9">
                          <select className="form-control" onChange={(e) => setReinvestProfit(e.target.value)}>
                            <option value={1} selected={reinvestProfit === 1 ? true : false}>Yes</option>
                            <option value={0} selected={reinvestProfit === 0 ? true : false}>No</option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label"></label>
                        <div className="col-sm-9">
                          <button className="btn btn-md btn-info" disabled={disabledButton} onClick={() => connectExchange()}>
                            Connect
                          </button>

                          {error !== "" && (
                            <Alert variant="danger" className="mt-3 alert-dismissible fade show">
                              {error}
                            </Alert>
                          )}

                        </div>
                      </div>

                      {/* <div className="form-group row">
                        <div className="col-sm-10">
                          <button type="submit" className="btn btn-md btn-info">
                            Connect
                          </button>
                        </div>
                      </div> */}

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

      </div>
    </>
  );
};

export default ExchangesConnect;

import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import { Col, Card, Accordion } from "react-bootstrap";
import {dashboardName} from '../../../baseURL';
import {SetBackgroundTheme} from "../../setBackgroundTheme";
import Cookies from 'js-cookie';

const ExchangesConnect = () => {
  const [activeDefault, setActiveDefault] = useState(0);

  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    SetBackgroundTheme(changeBackground, Cookies);
  }, []);

  const defaultAccordion = [
    {
      "title": "1. What is BETTR Trade?",
      "text": "BETTR Trade is an automated cryptocurrency trading platform that uses advanced algorithms to execute trades on your Binance account.",
      "bg": "primary"
    },
    {
      "title": "2. How does BETTR Trade work?",
      "text": "BETTR Trade uses advanced algorithms to analyze market conditions and execute trades on your Binance account automatically. The algorithms handle all aspects of trading, including buying at low prices and selling at high prices, while incorporating risk management techniques like stop-loss limits to protect your investments. With 24/7 trading, BETTR Trade helps you capitalize on market opportunities without needing constant attention.",
      "bg": "primary"
    },
    {
      "title": "3. Is BETTR Trade suitable for beginners?",
      "text": "Yes, BETTR Trade is suitable for beginners. The platform automates the entire trading process, allowing users to earn profits without needing extensive knowledge of the crypto market.",
      "bg": "primary"
    },
    {
      "title": "4. How do I sign up for BETTR Trade?",
      "text": "Signing up for BETTR Trade is quick and easy. All you need is an active email address. Simply visit the BETTR Trade website, click on the 'Sign Up' button, enter your email, and follow the instructions to create your account.",
      "bg": "primary"
    },
    {
      "title": "5. What information is required to create an account?",
      "text": "To create an account on BETTR Trade, you only need to provide an email address and set a password. Once registered, you’ll need to connect your Binance account. To create a new Binance account, you’ll need the following information: Email Address or Mobile Number, Password, Identity Verification (KYC).",
      "bg": "primary"
    },
    {
      "title": "6. Can I connect multiple cryptocurrency exchange accounts?",
      "text": "Yes, you can connect multiple cryptocurrency exchange accounts to BETTR Trade.",
      "bg": "primary"
    },
    {
      "title": "7. What exchanges does BETTR Trade support?",
      "text": "Currently, BETTR Trade supports Binance as its primary cryptocurrency exchange for automated trading.",
      "bg": "primary"
    },
    {
      "title": "8. Can I customize or create my own trading strategies?",
      "text": "No, BETTR Trade does not allow users to customize or create their own trading strategies. The platform uses pre-set, advanced algorithms designed to analyze market conditions and execute trades automatically for optimal results.",
      "bg": "primary"
    },
    {
      "title": "9. How often are trades executed?",
      "text": "Trade frequency depends on market conditions. On average, BETTR Trade executes 3-5 positions per day. However, this can vary-sometimes it may execute 1-2 trades, and on other days, it could open 9-10 positions based on opportunities identified by the algorithm.",
      "bg": "primary"
    },
    {
      "title": "10. What happens if the market fluctuates unexpectedly?",
      "text": "If the market fluctuates unexpectedly, BETTR Trade's advanced algorithms incorporate risk management techniques to protect your investments. This includes features like stop-loss limits and DCA to minimize potential losses and prevent significant capital drawdowns. The system continuously monitors market conditions and adjusts trading decisions in real time to respond to sudden changes, helping to safeguard your portfolio.",
      "bg": "primary"
    },
    {
      "title": "11. Does BETTR Trade support margin or futures trading?",
      "text": "No, BETTR Trade does not support margin or futures trading. The platform only operates on spot trading, ensuring a simpler and lower-risk trading experience.",
      "bg": "primary"
    },
    {
      "title": "12. Does BETTR Trade have access to my funds?",
      "text": "No, BETTR Trade does not have access to your funds. You only provide permission for the platform to execute trades on your account through API Key, but it does not have withdrawal access. Your funds remain secure in your exchange account.",
      "bg": "primary"
    },
    {
      "title": "13. What data does BETTR Trade collect, and how is it used?",
      "text": "BETTR Trade collects the following data:\n\n* Email: Used for account authentication and contacting you regarding your account or support.\n* API Key: Required for connecting your Binance account to execute trades, allowing the platform to make trades on your behalf without access to your funds.\n* Trading Data: Includes information related to your trades (e.g., order history, positions) for the purpose of executing and optimizing trading strategies.\n\nThis data is used solely to facilitate the trading process and provide customer support. Your data is protected and handled securely to ensure your privacy and account safety.",
      "bg": "primary"
    },
    {
      "title": "14. What pricing plans are available?",
      "text": "BETTR Trade offers a free trial, allowing you to experience the platform before committing. Additionally, BETTR Trade offers a free demo account.\n\nAfter the trial, the platform operates on a profit-sharing model, where the cost is 10% of the net profit generated from your trades. You only pay when you earn, ensuring that costs are directly tied to your success.",
      "bg": "primary"
    },
    {
      "title": "15. What should I do if I encounter an issue with the platform?",
      "text": "If you encounter an issue with the platform, you can reach out to BETTR Trade's customer support team. They offer comprehensive assistance and are available to help resolve any questions or problems you may have.",
      "bg": "primary"
    },
    {
      "title": "16. How can I contact customer support?",
      "text": "You can contact BETTR Trade's customer support by emailing info@bettrtrade.com or by using the contact form available in the Dashboard. The support team will assist you with any questions or issues you may encounter.",
      "bg": "primary"
    },
    {
      "title": "17. Is there a user guide or tutorial available?",
      "text": "Yes, BETTR Trade provides both video and text-based tutorials to help users get started and make the most of the platform. These guides cover various aspects of the platform, including account setup, connecting your exchange, and using the automated trading features. You can access them through the 'Get Started' section in the Dashboard.",
      "bg": "primary"
    },
    {
      "title": "18. Can I test the platform with a demo account before committing real funds?",
      "text": "Yes, BETTR Trade offers a free demo account that allows you to test the platform and its features without committing real funds. This gives you the opportunity to experience the trading process and understand how the platform works before making any financial commitment.",
      "bg": "primary"
    },
    {
      "title": "19. How much profit can I expect to make with BETTR Trade?",
      "text": "The profit you can make with BETTR Trade depends on market conditions and trading opportunities. Based on historical data, the average profit has ranged from 3% to 10%, but BETTR Trade does not guarantee profits. Market fluctuations can affect results, and performance may vary depending on various factors, including market trends and the strategies in play.",
      "bg": "primary"
    },
    {
      "title": "20. Does BETTR Trade guarantee profits?",
      "text": "No, BETTR Trade does not guarantee profits. While the platform uses advanced algorithms to optimize trading strategies, the results depend on market conditions and other factors.",
      "bg": "primary"
    }
  ];

  return (
    <>
      <div className="row">

        <Col xl="12">
          <Card>
            <Card.Header className="d-block card-header">
              <h4 className="card-title">Get Started</h4>
              <Card.Text className="m-0 subtitle">
                Frequently Asked Questions
              </Card.Text>
            </Card.Header>
            <Card.Body className="card-body">
              {/* <!-- Default accordion --> */}
              <Accordion
                className="accordion accordion-primary accordion-header-bg"
                defaultActiveKey="0"
              >
                {defaultAccordion.map((d, i) => (
                  <div className="accordion__item" key={i}>
                    <Accordion.Toggle
                      as={Card.Text}
                      eventKey={`${i}`}
                      className={`accordion__header accordion__header--info rounded-lg ${
                        activeDefault === i ? "" : "collapsed"
                      }`}
                      onClick={() =>
                        setActiveDefault(activeDefault === i ? -1 : i)
                      }
                    >
                      <span className="accordion__header--text">{d.title}</span>
                      <span className="accordion__header--indicator"></span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={`${i}`}>
                      <div className="accordion__body--text">{d.text}</div>
                    </Accordion.Collapse>
                  </div>
                ))}
              </Accordion>
            </Card.Body>
          </Card>
        </Col>
      </div>
    </>
  );
};

export default ExchangesConnect;

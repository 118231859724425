import React, {useState} from "react";
import { Link, useLocation } from "react-router-dom";
import { Alert } from "react-bootstrap";
// image
import logo from "../../images/logo/logo_full.png";
import loadingGif from "../../images/loading.gif";

import axios from 'axios';
import { baseURL } from "../../baseURL";
import Cookies from 'js-cookie';

const Register = ({ history }) => {
  const location = useLocation();

  // Parse query string
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');
  const unsubscribe_code = searchParams.get('unsubscribe_code');

  const [unsubscribedStatus, setUnsubscribedStatus] = useState(false);
  const [subscribedStatus, setSubscribedStatus] = useState(false);
  
  const [error, setError] = useState('');
  const [disabledButton, setDisabledButton] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const submitHandler = async (e) => {
    e.preventDefault();
    setDisabledButton(true);
    setLoading(true);

    const data = {
      email: email,
      unsubscribe_code: unsubscribe_code
    }

    try {
      const response = await axios.post(baseURL+'/api/user/unsubscribe', data);
      if(response.data.status === 'ok') {
        setUnsubscribedStatus(true);
        // history.push("/page-confirm-email");
      } else {
        setError(response.data.text);
      }
    } catch(err) {

    }
    
    setDisabledButton(false);
    setLoading(false);
  };
  
  const submitCancelSubscribe = async (e) => {
    e.preventDefault();
    setDisabledButton(true);
    setLoading(true);

    const data = {
      email: email,
      unsubscribe_code: unsubscribe_code
    }

    try {
      const response = await axios.post(baseURL+'/api/user/subscribe_back', data);
      if(response.data.status === 'ok') {
        setUnsubscribedStatus(true);
        setSubscribedStatus(true);
        // history.push("/page-confirm-email");
      } else {
        setError(response.data.text);
      }
    } catch(err) {

    }
    
    setDisabledButton(false);
    setLoading(false);
  };

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/">
                        <img alt="" src={logo} width="120" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-0 ">Unsubscribe</h4>
                    <p className="text-center mb-4 ">If you unsubscribe from our email newsletter, you will no longer receive any messages. You can update these settings in your Dashboard.</p>

                    {error !== "" && (
                      <Alert variant="danger" className="alert-dismissible fade show">
                        {error}
                      </Alert>
                    )}

                    {unsubscribedStatus == true && (
                      <>
                      {subscribedStatus == false && (
                        <>
                          <Alert variant="success" className="alert-dismissible fade show">
                            You have successfully unsubscribed from the newsletter.
                          </Alert>
                            <form onSubmit={(e) => submitCancelSubscribe(e)}>
                              <div className="text-center mt-4">
                                <button type="submit" className="btn btn-success text-white btn-block justify-content-center align-items-center h-100" disabled={disabledButton}>
                                  {loading === false && (
                                    <span>Cancel</span>
                                  )}
                                  {loading === true && (
                                    <span><img alt="" src={loadingGif} width="18" /></span>
                                  )}
                                  {/* <span>Sign up</span> */}
                                </button>
                              </div>
                            </form>
                          </>
                        )}

                        {subscribedStatus == true && (
                          <Alert variant="success" className="alert-dismissible fade show">
                            You have successfully subscribed to the newsletter.
                          </Alert>
                        )}
                      </>
                    )}

                    {unsubscribedStatus == false && (
                      <form onSubmit={(e) => submitHandler(e)}>
                        <div className="form-group">
                          <label className="mb-1 ">
                            <strong>Email</strong>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="hello@example.com"
                            value={email}
                            disabled={true}
                          />
                        </div>
                        
                        <div className="text-center mt-4">
                          <button type="submit" className="btn btn-primary btn-block justify-content-center align-items-center h-100" disabled={disabledButton}>
                            {loading === false && (
                              <span>Unsubscribe</span>
                            )}
                            {loading === true && (
                              <span><img alt="" src={loadingGif} width="18" /></span>
                            )}
                            {/* <span>Sign up</span> */}
                          </button>
                        </div>
                      </form>
                    )}
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
